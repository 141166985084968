<template>
  <!-- 活动统计 -->
  <div class="page-container">
    <div class="page-top-tool">
      <!-- <el-button type="success" icon="el-icon-plus" size="mini">新增网点</el-button> -->
      <div class="right-search">
        <span>所属地区：</span>
        <el-select v-model="area" clearable filterable :disabled="loading" placeholder="请选择" size="mini" style="width: 160px;">
          <el-option
            v-for="item in areaList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
        <span style="margin-left: 20px;">团队经理：</span>
        <el-select v-model="fcode" placeholder="请选择" filterable :disabled="loading" clearable size="mini" style="width: 180px;">
          <el-option
            v-for="item in fnameOptions"
            :key="item.empcode"
            :label="`${item.empname} ${item.emparea ? '（'+item.emparea+'）' : ''}`"
            :value="item.empcode">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">规划师：</span>
        <el-select v-model="sname" placeholder="请选择" filterable :disabled="loading" clearable size="mini" style="width: 160px;">
          <el-option
            v-for="item in snameOptions"
            :key="item.id"
            :label="item.empname"
            :value="item.empname">
          </el-option>
        </el-select>
        <span style="margin-left: 10px">活动月份：</span>
        <el-date-picker
          v-model="month"
          type="month"
          size="mini"
          :clearable="false"
          :disabled="loading"
          placeholder="选择日期">
        </el-date-picker>
        <el-button type="primary" icon="el-icon-search" size="mini" :disabled="loading" style="margin: 0px 20px;" @click="onSearch">搜索</el-button>
      </div>
      <el-button type="success" size="mini" icon="el-icon-download" v-if="!loading" @click="exportExcel">导出</el-button>
    </div>
    <el-table
      v-loading="loading"
      element-loading-text="数据加载中"
      element-loading-spinner="el-icon-loading"
      :data="tableData"
      id="tableId"
      height="90%"
      style="width: 100%; font-size: 12px; margin: 10px 0px;"
      :header-cell-style="tableHeaderColor"
    >
      <el-table-column
        prop="empname"
        label="规划师">
      </el-table-column>
      <el-table-column
        prop="fname"
        label="所属团队">
      </el-table-column>
      <el-table-column
        prop="area"
        label="所属地区">
      </el-table-column>
      <el-table-column label="活动月份">
        <template>
          {{ formatMonth }}
        </template>
      </el-table-column>
      <el-table-column label="计划场次">
        <template slot-scope="scope">
          <div style="color: #E6A23C;font-weight: 600;">
            {{ showPreactiveNum(scope.row.preActives) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="实办场次">
        <template slot-scope="scope">
          <div style="color: #67C23A;font-weight: 600;">
            {{ scope.row.actives.length }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="规划师电话">
      </el-table-column>
      <!-- <el-table-column label="条幅照片" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('tiaofu', scope.row.tiaofu)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="海报照片" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('haibao', scope.row.haibao)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="签到照片" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('qiandao', scope.row.qiandao)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="现场照片" width="80">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showImages('xianchang', scope.row.xianchang)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" width="80">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.state == '1'" type="warning" size="mini">待审核</el-tag>
          <el-tag v-else-if="scope.row.state == '2'" type="success" size="mini">已通过</el-tag>
          <el-tag v-else-if="scope.row.state == '3'" type="danger" size="mini">未通过</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="showActiveDetail(scope.row)">查看详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <!-- <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="pageSize"
      hide-on-single-page
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination> -->

    <!-- 查看图片 -->
    <el-dialog :visible.sync="dialogImagesVisible" @close="imageDialogClose" :show-close="false">
      <div class="show-images" style="text-align: center;">
        <el-carousel height="450px" v-if="imgType == 'xianchang'" indicator-position="outside">
          <el-carousel-item v-for="item in imgUrl" :key="item">
            <img :src="item" />
          </el-carousel-item>
        </el-carousel>
        <img v-else :src="imgUrl[0]" />
      </div>
    </el-dialog>

    <!-- 查看活动列表 -->
    <el-dialog :title="activeListTitleText" :visible.sync="dialogActiveListVisible" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-table :data="showActiveList">
        <el-table-column property="activitydate" label="日期" width="120"></el-table-column>
        <el-table-column property="storename" label="活动网点"></el-table-column>
        <el-table-column property="location" label="活动定位" width="260"></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" size="mini" @click="showActiveDetailByEmployee(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 查看活动详情 -->
    <el-dialog title="活动详情" :visible.sync="dialogActiveDetailVisible" @close="imageDialogClose" :show-close="false" >
      <div class="active-detail" style="text-align: center;">
        <img v-if="activeObj.state == '1'" src="@/assets/daishen.png" class="shenpi-resuilt-img" />
        <img v-else-if="activeObj.state == '2'" src="@/assets/pass.png" class="shenpi-resuilt-img" />
        <img v-else-if="activeObj.state == '3'" src="@/assets/nopass.png" class="shenpi-resuilt-img" />
        <el-descriptions class="margin-top" :column="2" border>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-user"></i>
              规划师
            </template>
            {{ activeObj.empname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-mobile-phone"></i>
              手机号
            </template>
            {{ activeObj.sphone }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-custom"></i>
              所属团队
            </template>
            {{ activeObj.fname }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-location-outline"></i>
              定位信息
            </template>
            <el-tag size="mini">{{ activeObj.location }}}</el-tag>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              <i class="el-icon-s-promotion"></i>
              活动网点
            </template>
            {{ activeObj.storename }}
          </el-descriptions-item>
          <el-descriptions-item v-if="activeObj.state == '3'">
            <template slot="label">
              <i class="el-icon-s-check"></i>
              驳回理由
            </template>
            {{ activeObj.cause }}
          </el-descriptions-item>
        </el-descriptions>
        <div class="active-images-layout">
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.tiaofu}`)">
            <img :src="`${baseurl}/images/${activeObj.tiaofu}`" width="120px" height="120px">
            <div>条幅照片</div>
          </div>
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.qiandao}`)">
            <img :src="`${baseurl}/images/${activeObj.qiandao}`" width="120px" height="120px">
            <div>签到照片</div>
          </div>
          <div class="active-images-item" @click="showImageDetail(`${baseurl}/images/${activeObj.haibao}`)">
            <img :src="`${baseurl}/images/${activeObj.haibao}`" width="120px" height="120px">
            <div>海报照片</div>
          </div>
          <div v-for="(item,index) in activeObj.xianchang" :key="index" class="active-images-item" @click="showImageDetail(`${baseurl}/images/${item}`)">
            <img :src="`${baseurl}/images/${item}`" width="120px" height="120px">
            <div>现场照片{{ index + 1 }}</div>
          </div>
        </div>
      </div>
      <div class="active-detail-bottom-close-btn">
        <el-button size="mini" plain @click="onCloseDialogByEmployee">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import global from '@/utils/global.js'
import FileSaver from "file-saver"
import * as XLSX from "xlsx"
export default {
  name: 'ReportPage',
  data() {
    return {
      allEmployeeList: [], // 所有员工数据
      allActiveList: [], // 所有活动数据
      allPreactiveList: [], // 所有活动计划数据
      allStoreList: [], // 所有网点数据
      fnameOptions: [], // 可选经理
      snameOptions: [], // 可选规划师
      areaList: [], // 所有地区
      area: '', // 选择地区
      fcode: '', // 选择经理
      sname: '', // 选择规划师
      month: '', // 月份
      formatMonth: '', // 格式化月份
      reportData: [], // 统计总数据
      tableData: [],
      showActiveList: [], // 查看活动详情的数据
      dialogActiveListVisible: false, // 查看活动详情列表弹框
      activeListTitleText: '', // 查看活动详情列表弹框标题
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imgUrl: [],
      imgType: '',
      dialogImagesVisible: false,
      dialogActiveDetailVisible: false,
      activeObj: {},
      baseurl: global.baseurl
    };
  },
  watch: {
    allEmployeeList: {
      deep: true,
      immediate: true,
      handler(val) {
        this.fnameOptions = val.filter(item => {
          return item.role == '1'
        })
      }
    },
    allStoreList: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length > 0) {
          this.fnameOptions.forEach(f => {
            val.forEach(store => {
              if (f.empcode == store.fcode) {
                f.emparea = store.area
              }
            })
          })
        }
      }
    },
    fnameOptions: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.length <= 0) {
          this.snameOptions = []
        }
      }
    },
    fcode(val) {
      if (val) {
        this.sname = ''
        if (val) {
          this.snameOptions = this.allEmployeeList.filter(item => {
            return item.role == '2' && item.fcode == val
          })
        } else {
          this.snameOptions = []
        }
      }
    },
  },
  mounted() {
    this.month = new Date()
    const Y = this.month.getFullYear()
    const M = this.month.getMonth() + 1
    this.formatMonth = `${Y}/${M}`
    this.initData()
  },

  methods: {
    // 初始化数据
    async initData() {
      this.loading = true
      // 查询所有员工
      try {
        const empResp = await this.$http({
          method: 'post',
          url: 'employee_findall'
        })

        if (empResp.errno == 0) {
          this.allEmployeeList = empResp.data.data
        } else {
          this.$message.error('员工数据查询超时')
          this.loading = false
        }
      } catch(e) {
        this.$message.error('网络异常，员工数据查询失败')
        this.loading = false
      }

      // 查询所有活动
      try {
        const activeResp = await this.$http({
          method: 'post',
          url: 'activity_findall',
          params: {
            state: '2'
          }
        })

        if (activeResp.errno == 0) {
          this.allActiveList = activeResp.data.data
        } else {
          this.$message.error('活动数据查询超时')
          this.loading = false
        }
      } catch(e) {
        this.$message.error('网络异常，活动数据查询失败')
        this.loading = false
      }

      // 查询所有活动计划
      try {
        const preactiveResp = await this.$http({
          method: 'post',
          url: 'preactivity_findall',
          params: {
            state: '1'
          }
        })

        if (preactiveResp.errno == 0) {
          this.allPreactiveList = preactiveResp.data.data
        } else {
          this.$message.error('填报活动数据查询超时')
          this.loading = false
        }
      } catch(e) {
        this.$message.error('网络异常，填报活动数据查询失败')
        this.loading = false
      }

      // 查询所有网点
      try {
        const storeResp = await this.$http({
          method: 'post',
          url: 'store_findall'
        })

        if (storeResp.errno == 0) {
          this.allStoreList = storeResp.data.data
        } else {
          this.$message.error('网点数据查询超时')
          this.loading = false
        }
      } catch(e) {
        this.$message.error('网络异常，网点数据查询失败')
        this.loading = false
      }

      // 查询所有地区
      try {
        const areaResp = await this.$http({
          method: 'post',
          url: 'area_findall'
        })

        if (areaResp.errno == 0) {
          this.areaList = areaResp.data.data
        } else {
          this.$message.error('地区数据查询超时')
          this.loading = false
        }
      } catch(e) {
        this.$message.error('网络异常，地区数据查询失败')
        this.loading = false
      }

      this.setReport()
    },
    // 整合数据
    setReport() {
      const tempList = []

      for (let i = 0; i < this.allEmployeeList.length; i++) {
        const emp = this.allEmployeeList[i]
        emp.actives = []
        emp.preActives = []
        for (let j = 0; j < this.allActiveList.length; j++) {
          const active = this.allActiveList[j]
          if (active.activitydate.startsWith(this.formatMonth) && active.empcode == emp.empcode) {
            emp.actives.push(active)
          }
        }
        for (let k = 0; k < this.allPreactiveList.length; k++) {
          const preActive = this.allPreactiveList[k]
          if (preActive.month == this.formatMonth) {
            const details = JSON.parse(preActive.details)
            details.forEach(item => {
              if (item.scode == emp.empcode) {
                item.month = preActive.month
                item.fname = preActive.fname
                item.fcode = preActive.fcode
                emp.preActives.push(item)
              }
            })
          }
        }
        tempList.push(emp)
      }

      
      this.reportData = tempList.filter(item => {
        let fnameResult = true
        if (this.fcode) {
          fnameResult = this.fcode == item.fcode
        }
        let snameResult = true
        if (this.sname) {
          snameResult = this.sname == item.empname
        }
        return !(item.actives.length <= 0 && item.preActives.length <= 0) && fnameResult && snameResult
      })
      this.total = this.reportData.length
      this.currentPage = 1
      this.renderTableData()
      this.loading = false
    },
    // 渲染数据
    renderTableData() {
      // 分页查询
      // const start = (this.currentPage - 1) * this.pageSize
      // const end = start + this.pageSize
      // this.tableData = this.reportData.filter((item, index) => {
      //   return index >= start && index < end
      // })

      // 不分页
      this.tableData = this.reportData
      if (this.tableData.length > 0) {
        this.addAreaFiled()
      }

      if (this.area) {
        this.tableData = this.tableData.filter(item => item.area == this.area)
        this.currentPage = 1
        this.total = 0
      }
    },
    // 查询的数据添加地区字段
    addAreaFiled() {
      // let storeList = sessionStorage.getItem('storeList')
      // if (!storeList) {
      //   return
      // }
      // storeList = JSON.parse(storeList)
      this.tableData.forEach(data => {
        for (let i = 0; i < this.allStoreList.length; i++) {
          const item = this.allStoreList[i]
          if (data.empcode == item.scode) {

            data.area = item.area
            data.fname = item.fname
            data.fcode = item.fcode
          }
        }
      })
    },
    tableHeaderColor({row, column, rowIndex, columnIndex}) {
      return 'background-color: #F2F2F2;color:#101010;'
    },
    // 搜索
    onSearch() {
      if (!this.month) {
        this.$message.warning("请选择要查询的活动月份！")
        return
      }
      this.currentPage = 1
      const Y = this.month.getFullYear()
      const M = this.month.getMonth() + 1
      this.formatMonth = `${Y}/${M}`
      // this.setReport()
      this.initData()
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.renderTableData()
    },
    // 查看图片弹框
    showImages(type, img) {
      this.dialogImagesVisible = true
      this.imgType = type
      this.imgUrl = []
      if (type == 'xianchang') {
        const imgArry = img.split(',')
        imgArry.forEach(item => {
          this.imgUrl.push(`${this.baseurl}/images/${item}`)
        })
      } else {
        this.imgUrl = [`${this.baseurl}/images/${img}`]
      }
    },
    // 查看图片弹框关闭
    imageDialogClose() {
      this.imgUrl = []
      this.imgType = ''
      this.activeObj = {}
    },
    // 查看活动详情
    showActiveDetail(row) {
      this.showActiveList = row.actives
      this.dialogActiveListVisible = true
      this.activeListTitleText = `${row.empname} ${this.formatMonth}月活动详情`
    },
    // 根据员工查看活动详情
    showActiveDetailByEmployee(row) {
      this.activeObj = row
      if (typeof this.activeObj.xianchang == 'string') {
        this.activeObj.xianchang = this.activeObj.xianchang.split(',')
      }
      this.dialogActiveDetailVisible = true
    },
    // 关闭根据员工查看活动详情
    onCloseDialogByEmployee() {
      this.dialogActiveDetailVisible = false
      this.activeObj = {}
    },
    // 查看大图
    showImageDetail(url) {
      var iWidth = 950 //弹出窗口的宽度
      var iHeight = 560 //弹出窗口的高度
      //获得窗口的垂直位置
      var iTop = (window.screen.availHeight - 30 - iHeight) / 2;
      //获得窗口的水平位置
      var iLeft = (window.screen.availWidth - 10 - iWidth) / 2;
      window.open(url,'_blank','width=' + iWidth + ',height=' + iHeight + ',top=' + iTop + ',left=' + iLeft + ',menubar=no,toolbar=no, status=no,scrollbars=yes')
    },
    // 导出Excel
    exportExcel() {
      //转换成excel时，使用原始的格式
				var xlsxParam = { raw: true };
				let fix = document.querySelector(".el-table__fixed");
				let wb;
        //判断有无fixed定位，如果有的话去掉，后面再加上，不然数据会重复
				if (fix) {
				  wb = XLSX.utils.table_to_book(
				  document.querySelector("#tableId").removeChild(fix),xlsxParam
				  );
				  document.querySelector("#tableId").appendChild(fix);
				} else {
				  wb = XLSX.utils.table_to_book(document.querySelector("#tableId"),xlsxParam);
				}
				var wbout = XLSX.write(wb, {
          bookType: "xlsx",
          bookSST: true,
          type: "array",
				});
				try {
          const Y = this.month.getFullYear()
          const M = this.month.getMonth() + 1
          FileSaver.saveAs(
            new Blob([wbout], { type: "application/octet-stream" }),
            `${Y}年${M}月活动统计报表.xlsx`
          ); //文件名
				} catch (e) {
				if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout
    },
    showPreactiveNum(preactives) {
      let count = 0
      preactives.forEach(item => {
        count += item.num
      })
      return count
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}

::v-deep .el-dialog {
  width: 60%;
  height: 650px;
  top: -20px;
}

.show-images {
  height: 480px;
}
.active-detail {
  position: relative;
  .shenpi-resuilt-img {
    position: absolute;
    width: 120px;
    top: -70px;
    right: 0px;
    opacity: 0.6;
  }

  .active-images-layout {
    display: flex;
    flex-wrap: wrap;
    .active-images-item {
      margin: 20px;
      cursor: pointer;
    }
  }
}

.active-detail-bottom-close-btn {
  text-align: right;
  margin-top: 10px;
}


/* 修改滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
	width: 4px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.15);
	background: rgba(0, 0, 0, 0.15);
}
</style>